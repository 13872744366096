<template>
  <ui-component-modal
    :modalTitle="$t('Components.Events.EventName.Edit.Main_Title')"
    :disableSaveButton="mEventData.Name.length < 2"
    :onClickCancel="onClickCancel"
    :onClickSave="saveEvent"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <ui-base-input-field
        v-model="mEventData.Name"
        :error="nameErrors"
        :required="true"
        :showLabel="false"
        fieldType="text"
        @keyup.enter="saveEvent"
        @input="$v.mEventData.Name.$touch()"
        @blur="$v.mEventData.Name.$touch()"
      />
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import eventProvider from '@/providers/event'

import { required, minLength } from 'vuelidate/lib/validators'
const touchMap = new WeakMap()

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mEventData: null,
    }
  },

  /**
   * Form validation rules
   */
  validations() {
    let output = {
      Name: {
        required,
        minLength: minLength(2),
      },
    }
    return {
      mEventData: output,
    }
  },

  computed: {
    ...mapState('eventStore', ['eventData']),

    nameErrors() {
      const errors = []
      if (!this.$v.mEventData.Name.$dirty) return errors
      !this.$v.mEventData.Name.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      !this.$v.mEventData.Name.minLength &&
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 2 }))
      return errors
    },
  },

  created() {
    this.mEventData = this.$objectHelper.cleanSource(this.eventData)
  },

  methods: {
    ...mapMutations('eventStore', ['setEventData']),

    saveEvent() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          // 'ERROR'
          self.isSaving = false
        } else {
          eventProvider.methods
            .updateEvent(self.mEventData)
            .then((response) => {
              if (response.status === 200) {
                self.isSavingSuccess = true
                self.setEventData(response.data)

                let t = setTimeout(() => {
                  self.onClickCancel()
                  clearTimeout(t)
                }, 1500)
              }
            })
            .catch((e) => {
              ////console.log(error.response)
              self.isSavingError = true
            })
            .finally(() => {
              self.isSaving = false
            })
        }
      }
    },
  },
}
</script>
